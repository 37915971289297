<div id="flightResults" class="flight-search-results__ticket-a flex-column-container">
  <div
    *ngIf="flights && flights?.length; else noResults"
    class="flight-search-results__sort-items-container">
    <div
      class="flight-title"
      *ngIf="!isExchange && search?.chosenSearchType === FlightSearchType.oneWay">
      <h2 *ngIf="search?.departLocation && search?.arriveLocation">
        {{ search?.departLocation['destination'] }}
        <span i18n="@@flilghtSearchTo">to</span>
        {{ search?.arriveLocation['destination'] }}
      </h2>
      <div>
        <span i18n="@@OutBoundDate">Outbound Date</span>:
        {{
          search?.outTimeCriteria === 'Anytime'
            ? (search?.outBoundDate | sabsDate : 8)
            : (search?.outBoundDate | sabsDate : 5 | translate)
        }}
      </div>
    </div>

    <div *ngIf="searchParams.bySchedule && multiCityByScheduleFlightsList.length">
      <app-multi-city-flights-by-schedule
      [multiCityByScheduleFlightsList]="multiCityByScheduleFlightsList"
      [source]="source"
      [requiresReason]="requiresReason"
      (removeAndSearchAgain)="removeItems($event)"
      (removeItem)="removeMultiCityFlight($event)"
      ></app-multi-city-flights-by-schedule>
    </div>

    <div class="flight-title" *ngIf="isExchange && flights[0]">
      <h2>
        {{ flights[0].outboundFlights[0].originAirportName }}
        <span i18n="@@flilghtSearchTo">to</span>
        {{
          flights[0].outboundFlights[flights[0].outboundFlights.length - 1].destinationAirportName
        }}
      </h2>
      <div>
        <span i18n="@@OutBoundDate">Outbound Date</span>:
        {{ flights[0].outboundFlights[0].dateTimeDeparting | sabsDate : 8 }}
      </div>
      <div *ngIf="flights[0].inboundFlights[0]">
        <span i18n="@@InBoundDate">Inbound Date</span>:
        {{ flights[0].inboundFlights[0].dateTimeDeparting | sabsDate : 8 }}
      </div>
    </div>
    <p
      class="flight-search-results__sort-items flex-row-container--margin-tb showing-container"
      *ngIf="combinedFlightsKeys?.length && !isPricingExchange">
      Showing {{ flightOffset }} to
      {{
        combinedFlightsKeys.length > flightBoundary ? flightBoundary : combinedFlightsKeys.length
      }}
      of {{ combinedFlightsKeys.length }} flights
    </p>
    <div *ngIf="searchParams.bySchedule">
      <app-light-toggle-group tabindex="-1">
        <ng-container *ngFor="let item of search?.multiDestination; let i = index">
          <app-light-toggle-btn
            [selected]="searchParams.selectedSegment === i"
            (click)="searchParams.selectedSegment = i; seachFlightsBySchedule()"
            tabindex="-1">
            <span i18n="@@muliCityFlight">Flight</span>: {{i+1}}
          </app-light-toggle-btn>
        </ng-container>
      </app-light-toggle-group>
    </div>
  </div>
  <div *ngIf="searchParams.bySchedule && multiCityByScheduleCombinedFlightsList">
    <div class="flex-column-container" *ngFor="let flightKey of currentFlightSlice; let i = index">
      <app-flight-display 
        [isExchange]="isExchange"
        [isPricingExchange]="isPricingExchange"
        [flightIndex]="i"
        [isDualFlight]="isDual" 
        [isDualReturn]="isDualReturn" 
        (selectedMultiCityFlight)="selectedMultiCityFlight($event)"
        (deselectMultiCityFlight)="deselectMultiCityFlight(multiCityByScheduleCombinedFlightsList[flightKey])"
        [deselectItem]="checkAviability(multiCityByScheduleCombinedFlightsList[flightKey])"
        [selectedItemsId]="selectedItemsId"
        [disableItems]="disable()"
        [combinedFlight]="multiCityByScheduleCombinedFlightsList[flightKey]"
        (selectExchangeFlight)="selectExchangeFlight.emit($event)">
      </app-flight-display>
    </div>
  </div>
  <div *ngIf="!searchParams.bySchedule && combinedFlights">
    <div class="flex-column-container" *ngFor="let flightKey of currentFlightSlice; let i = index">
      <app-flight-display
        [isExchange]="isExchange"
        [isPricingExchange]="isPricingExchange"
        [flightIndex]="i"
        [isDualFlight]="isDual" 
        [isDualReturn]="isDualReturn" 
        [combinedFlight]="combinedFlights[flightKey]"
        (selectExchangeFlight)="selectExchangeFlight.emit($event)">
      </app-flight-display>
    </div>
  </div>
  <ngb-pagination
    *ngIf="flights && combinedFlightsKeys.length > 1"
    class="flex-row-container pageination"
    [collectionSize]="combinedFlightsKeys.length"
    [(page)]="currentPage"
    [pageSize]="pageSize"
    [maxSize]="deviceDetector.isMobile ? 2 : 4"
    (pageChange)="changePage($event)">
    <ng-template ngbPaginationNumber let-page><span class="visuallyhidden">Page&nbsp;</span>{{ page }}</ng-template>
  </ngb-pagination>
</div>

<ng-template #noResults>
  <div class="no_results_message background-10 no_results_flights" role="status">
    <h2 class="no_results_message_heading">
      <i class="ico-noflight-1 no_results_icon" aria-hidden="true"></i>Oh, no!
    </h2>
    <div>
      <p>
        <span i18n="@@flightResultsNoMatch"
          >We couldn't find any fares that match your criteria. Try</span
        >
        <strong i18n="@@flightResultsNoMatchB">&nbsp;updating your search criteria</strong>.
      </p>
      <p i18n="@@flightResultsContact">
        If you can't find exactly what you're looking for, contact a consultant.
      </p>
    </div>

    <ng-template #ReturnSearchTypeError>
      <p>
        <span i18n="@@ReturnSearchTypeError"
          >We couldn't find any return fares that match your current search criteria. Please select
          the Dual Singles tab to check if there are any available fares to display. If no fares
          have been returned try</span
        >
        <strong i18n="@@flightResultsNoMatchB">&nbsp;updating your search criteria</strong>.
      </p>
      <p i18n="@@ReturnSearchTypeErrorResultsContact">
        If you can't find exactly what you're looking for, please contact your Travel Manager or
        Online Support Team for further assistance.
      </p>
    </ng-template>
  </div>
</ng-template>

