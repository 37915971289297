<ng-container
    *ngIf="{
        loading: searchService.search_loading,
        results: (searchParams.results | async) || []
    } as data">
    <app-taxi-loading-screen [animated]="false"></app-taxi-loading-screen>
    <div>
        <h2 class="search_content_titles" i18n="@@taxis_searchCriteria">Search Criteria</h2>
        <a
            href="javascript:void(0)"
            (click)="skipToResultContent()"
            (keydown.enter)="skipToResultContent()"
            (keydown.space)="skipToResultContent()"
            role="link"
            class="sr-only sr-only-focusable"
            >Skip to Result Content</a
        >
        <app-invoked-user></app-invoked-user>
    </div>
    <app-validate-errors></app-validate-errors>
    <div class="taxi_research_form">
        <app-search-toggle>
            <app-taxi-search [resultsMode]="true"></app-taxi-search>
        </app-search-toggle>
        <app-risk-alerts [countryCode]="searchParams.toAddress?.Country"></app-risk-alerts>
    </div>

    <!-- <div class="flex-row-container flex-row-container--right flex-row-container--tb-margin">
    <app-sorting-toggle id='taxi'
                        (sortingSelected)="onSortingSelected($event)"
                        [sortingTypes]="sortingTypes"></app-sorting-toggle>
  </div> -->
    <div class="taxi-results-container primary-text-colour" *ngIf="!mapView">
        <app-no-results *ngIf="!data.results.length" [type]="taxiServiceType"></app-no-results>
        <div class="taxi-item" *ngFor="let item of data.results; let i = index">
            <div class="taxi-image">
                <ng-template #cmac class="taxi-image_inner">
                    <img [src]="cabIconUrl" alt="" />
                </ng-template>
                <!-- dont show images ontop of each other -->
                <div class="taxi-image_inner_1">
                    <img src="https://images.sabscorp.com/images/TourOp/png/{{item.supplierName}}.png" alt="" />
                </div>
                <div class="taxi-image_inner_2" *ngIf="item.additional?.providersPricing?.multiMedias?.multiMedia[1]?.mediaURL as icon; else cmac">
                    <img [src]="icon" alt="Groundspan" />
                </div>
            </div>

            <div class="taxi-description">
                <h2>{{ item.supplierName }}</h2>
                <app-preferred-icons 
                    [tmcPreferred] = "item.tmcPreferred"
                    [tmcPreferredLogoUrl] = "item.tmcPreferredLogoUrl"
                    [tmcPreferredMessage]="item.tmcPreferredMessage"
                    [clientPreferred] = "item.officePreferred || item.companyPreferred"
                    [companyPreferredMessage] = "item.companyPreferredMessage"
                    [officePreferredMessage] = "item.officePreferredMessage">
                </app-preferred-icons>
                <dl *ngIf="item?.vehicleType as vehicleType" class="inline-dl">
                    <dt class="light-fat" i18n="@@taxisVehicleType">Vehicle Type: </dt>
                    <dd>{{ vehicleType | translate }}</dd>
                </dl>

                <dl *ngIf="item?.fromLocation as fromLocation" class="inline-dl">
                    <dt class="light-fat" i18n="@@taxisFrom">From: </dt>
                    <dd>{{ fromLocation.address }}, {{ fromLocation.postcode }}</dd>
                </dl>
                <dl *ngIf="item?.toLocation as toLocation" class="inline-dl">
                    <dt class="light-fat" i18n="@@taxisTo">To: </dt>
                    <dd>{{ toLocation.address }}, {{ toLocation.postcode }}</dd>
                </dl>

                <dl *ngFor="let via of vias; let i = index" class="inline-dl">
                    <ng-container *ngIf="via">
                        <dt class="light-fat" i18n="@@taxisVia">Via{{i}}: </dt>
                        <dd>{{ via.postcode }}</dd>
                    </ng-container>
                </dl>

                <dl *ngIf="item?.departs as departs" class="time-dl">
                    <dt class="light-fat" i18n="@@taxisDate">Date: </dt>
                    <dd>{{ departs | sabsDate: 7 }}</dd>
                    <dt class="light-fat" i18n="@@taxisTime">Time: </dt>
                    <dd>{{ departs | sabsTimePipe: 'json_time' }}</dd>
                </dl>

                <section (mouseleave)="tt.close()" class="container">
                    <button
                        i18n-ngbTooltip
                        ngbTooltip="Go to map view"
                        (click)="goToMapView(item)"
                        aria-label="Go to map view"
                        triggers="manual"
                        #tt="ngbTooltip"
                        (mouseenter)="tt.open()"
                        class="light-btn light-btn-ico light-btn-primary">
                        <i class="ico-map" aria-hidden="true"></i>
                    </button>
                </section>
            </div>
            <div class="taxi-actions">
                <div class="taxi-details">
                    <dl class="taxi-detail" *ngIf="item?.tripDistanceMetres as tripDistanceMetres">
                        <dt i18n="@@taxisDistance" class="light-fat">Distance:</dt>
                        <dd>{{ tripDistanceMetres | sabsDistance: 'metres_to_miles' }}</dd>
                    </dl>
                    <dl
                        class="taxi-detail"
                        *ngIf="item?.tripDurationSeconds as tripDurationSeconds">
                        <dt i18n="@@taxisEstTime" class="light-fat">Est. Time:</dt>
                        <dd>{{ tripDurationSeconds | sabsSecondsPipe: 'hm' }}</dd>
                    </dl>
                    <dl class="taxi-detail">
                        <dt i18n="@@taxisPassengers" class="light-fat">Passengers:</dt>
                        <dd>{{ searchParams.adults + searchParams.children }}</dd>
                    </dl>
                    <dl class="taxi-detail">
                        <dt class="light-fat">CO2:</dt> 
                        <dl>{{ item.estimatedCO2 }}kg</dl>
                    </dl>
                </div>
                <div class="taxi-btn-container">
                    <button
                        (click)="addToCart(item)"
                        [disabled]="item.unavailable && !canOverride"
                        class="light-btn light-btn-fancy"
                        id="taxi-add-to-basket-{{i}}"
                        attr.aria-label="{{ item.price| currency: item.currency }} add to cart {{ item.supplier }}">
                        <span class="price-wrapper">
                          {{ item.price | currency: item.currency }}
                        </span>
                        <span i18n="@@taxiExecVat">(Excl VAT)</span>
                        <span i18n="@@taxisAddToCart">Add to basket</span>
                    </button>
                    <app-warning-tags [ticket]="item"></app-warning-tags>
                </div>
            </div>
        </div>
    </div>
    <app-map-view
        [title]="selectedItem.supplierName"
        bgColor="#33766f"
        [icon]="cabIconUrl"
        [center]="geolocation"
        [pins]="pins"
        [displayRoute]="true"
        *ngIf="mapView"
        (closeMapView)="onCloseMapView()"></app-map-view>
</ng-container>
