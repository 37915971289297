<div [ngClass]="{ border: altColour, alt_format: altColour }">
  <div *ngIf="!isReturn && !isMultiCity" class="flight-header" ><span i18n="@@flightLegDepart">Depart</span> - {{flight.outboundFlights[0]?.dateTimeDeparting | date: 'dd/MM/yyyy'}}</div>
  <div *ngIf="isReturn && !isMultiCity" class="flight-header return-flight"><span i18n="@@flightLegReturn">Return</span> - {{flight.inboundFlights[0]?.dateTimeDeparting | date: 'dd/MM/yyyy'}}</div>
  <div class="leg"
       *ngFor="let leg of legs; let i = index; let first = first; let last = last">
    <div *ngIf="isMultiCity" class="flight-header"><span i18n="@@flightLegDepart">Depart</span> - {{leg.dateTimeDeparting | date: 'dd/MM/yyyy'}}</div>
    <div class="flex-row-container amenities-leg-container">
      <div class="flight-logo-container">
        <app-image-with-error
          title="{{ flight.source }}"
          logoUrl="{{
            'https://images.sabscorp.com/images/TourOp/svg/' + leg.marketingCarrier + '.svg'
          }}"
          alt="{{leg.operatingCarrierName}} - {{ leg.marketingCarrier }} {{ leg.marketingFlightNumber }} - {{ flight.source }}"
          class="ie-width-fix">
        </app-image-with-error>
        <strong class="flight-carrier" id="flight-legs-carrier-{{isReturn ? 'return' : 'outbound'}}-{{flightIndex}}-{{i}}"> 
          <span i18n="@@flightLegsOperatedBy" class="operated-by-wrapper">Operated By</span>{{ leg.operatingCarrierName }} 
          <br>{{ leg.marketingCarrier }}{{ leg.marketingFlightNumber }}</strong>
        <!-- <strong class="busWarning"
        *ngIf="leg.equipmentCode === 'BUS'"
        i18n="@busLegWarning">*Please be advised that this leg is via bus*</strong> -->
      </div>
      <div class="leg_trip">
        <dl class="leg_start_location" [ngClass]="{ alt_colour: altColour }">
          <dt>
            <time>
            {{ parseDateToMoment(leg.dateTimeDeparting) | sabsTimePipe : 'timeString' }}
            </time>
          </dt>
          <dd class="flex-column-container">
            <strong>{{ leg.originAirportName }} ({{ leg.originAirport }})</strong>
            <span>{{
              leg.dateTimeDeparting | sabsDate : 2
            }}</span>
          </dd>
        </dl>
        <dl class="duration">
          <dd class="sr-only">duration</dd>
          <dt>
            <time>
              {{ leg.totalDuration | sabsTimePipe : 'min_duration'}}
            </time> 
          </dt>
          <dd>
            &nbsp; <i style="font-size: 1.5rem" class="ico-bus" ngbTooltip="This leg is operated by a Bus." attr.aria-label="This leg is operated by a Bus." *ngIf="leg.equipmentCode === FlightEquipment.Bus"></i>
          </dd>
        </dl>
        <dl class="leg_end_location" [ngClass]="{ alt_colour: altColour }">
          <dt>
            <time>
              {{ parseDateToMoment(leg.dateTimeArriving) | sabsTimePipe : 'timeString' }}
              <span class="day-difference"
                >{{ dayDifference(leg.dateTimeDeparting, leg.dateTimeArriving) }} 
              </span>
            </time>
          </dt>
          <dd class="flex-column-container">
            <strong>{{ leg.destinationAirportName }} ({{ leg.destinationAirport }})</strong>
            <span>{{
              leg.dateTimeArriving | sabsDate : 2
            }}</span>
          </dd>
        </dl>
        <div class="leg_line" aria-hidden="true">
          <div class="leg_line_line"></div>
        </div>
      </div>
    </div>
    <div class="legs_layover" *ngIf="!last && showTimeDifference(leg.dateTimeArrivingUTC, legs[i + 1].dateTimeDepartingUTC)">
      <i class="ico-transfer" aria-hidden="true"></i>
      <em i18n="@@flightLegsChangeAt">Change at</em>
      {{ leg.destinationAirportName }} ({{ leg.destinationAirport }})
      <span>{{
        this.timeDifference | sabsTimePipe: 'min_duration'
        }}</span>
    </div>
  </div>
</div>
