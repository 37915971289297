<app-loading-screen *ngIf="loadingTravellerProfile">
  <span title i18n="@@SearchLoadingTravellerProfilw">Fetching Traveller Profile</span>
</app-loading-screen>
<div class="app">
  <app-results-header [blank]="true" page="search"></app-results-header>

  <ng-container
      *ngIf="{
          allUserdata: userService.fullUserDetails.allDetails | async,
          services: userServices$ | async,
          errors: searchService.errors | async,
          loadingDate: searchService.loadingDate | async
      } as data">
      <div class="app-body wcag-checkbox" id="main" #main>
          <section class="app-left secondary-text-colour">
              <h1
                  class="search_content_titles"
                  i18n="@@searchTitle"
                  *ngIf="!showGuestTravellerForm"
                  aria-label="New Search, please select the product(s) you would like to book.">
                  New Search<span class="visuallyhidden"
                      >, please select the product(s) you would like to book.</span
                  >
              </h1>
              <div class="booking_panel_options background-67" *ngIf="!showGuestTravellerForm">
                  <div>
                      <div class="booking_options">
                          <app-product-checkbox
                              class="booking_options_option"
                              [service]="ServiceType.Flight"
                              *ngIf="data.services && data.services[ServiceType.Flight]"
                              (selected)="toggleOptionAttempt(ServiceType.Flight)">
                              <div class="item-label" aria-hidden="true">
                                  <i class="ico-flight"></i>
                                  <span id="booking-options-{{ServiceType.Flight}}" i18n="@@searchFlight"
                                      >Flight</span
                                  >
                              </div>
                          </app-product-checkbox>

                          <!-- For now just place in all four options, determine which are used by the user later, hardcoded for now -->

                          <app-product-checkbox
                              class="booking_options_option"
                              [service]="ServiceType.Rail"
                              *ngIf="data.services && data.services[ServiceType.Rail]"
                              (selected)="toggleOptionAttempt(ServiceType.Rail)">
                              <div class="item-label" aria-hidden="true">
                                  <i class="ico-rail" aria-hidden="true"></i>
                                  <span id="booking-options-{{ServiceType.Rail}}">
                                      <span i18n="@@searchRail" *ngIf="data.services[ServiceType.Rail][0].name !== ServiceProvider.Trainline;else Trainline">UK Rail</span>
                                      <ng-template #Trainline>
                                          <span i18n="@@ProfileInformation-Rail">Rail</span> 
                                      </ng-template>
                                  </span>
                              </div>
                          </app-product-checkbox>
                          <app-product-checkbox
                              class="booking_options_option"
                              [service]="ServiceType.Eurostar"
                              *ngIf="
                                  data.services && data.services[ServiceType.Eurostar]
                              "
                              (selected)="
                                  toggleOptionAttempt(ServiceType.Eurostar);
                              ">
                              <div class="item-label" aria-hidden="true">
                                  <i class="custom_icon-eurostar" aria-hidden="true"></i>
                                  <span
                                      id="booking-options-{{ServiceType.Eurostar}}"
                                      i18n="@@searchEurostar"
                                      >Eurostar
                                  </span>
                              </div>
                          </app-product-checkbox>
                          <app-product-checkbox
                              class="booking_options_option"
                              [service]="ServiceType.Hotel"
                              *ngIf="data.services && data.services[ServiceType.Hotel]"
                              (selected)="toggleOptionAttempt(ServiceType.Hotel)">
                              <div class="item-label" aria-hidden="true">
                                  <i class="ico-bed" aria-hidden="true"></i>
                                  <span id="booking-options-{{ServiceType.Hotel}}" i18n="@@searchHotel"
                                      >Hotel</span
                                  >
                              </div>
                          </app-product-checkbox>
                          <app-product-checkbox
                              class="booking_options_option"
                              [service]="ServiceType.Car"
                              *ngIf="data.services && data.services[ServiceType.Car]"
                              (selected)="toggleOptionAttempt(ServiceType.Car)">
                              <div class="item-label">
                                  <i class="ico-car" aria-hidden="true"></i>
                                  <span id="booking-options-{{ServiceType.Car}}" i18n="@@searchCar">Car</span>
                              </div>
                          </app-product-checkbox>
                          <div
                              class="booking_options_others"
                              appClickOutSide
                              (clickOutside)="closeOthers()"
                              *ngIf="hasOthers">
                              <div class="others_radio_button_holder">
                                  <div class="others_radio_button_holder"
                                       role="button"
                                       (click)="openOthers($event)"
                                       (keydown.enter)="openOthers($event)"
                                       (keydown.space)="openOthers($event)"
                                       tabindex="0"
                                       aria-label="Open other options"
                                       i18n-aria-label="@@OpenOtherOptions"
                                       attr.aria-expanded="{{ othersExpanded }}">
                                      <div
                                          [class.booking_option_toggle_active]="anyOthersSelect()"
                                          class="booking_option_toggle booking_others_toggle"
                                          [attr.aria-checked]="anyOthersSelect()"
                                          aria-labelledby="booking-options-others"
                                          aria-hidden="true">
                                          <i
                                              *ngIf="anyOthersSelect()"
                                              class="ico-check-1 booking_option_toggle_check_active"></i>
                                      </div>
                                      <span
                                              id="
                                              booking-options-others"
                                              class="search-extras--space"
                                              i18n="@@searchOthers"
                                              >OTHERS</span
                                          >
                                  </div>
                                  <div
                                      class="others-container"
                                      id="others-container"
                                      [ngClass]="{ 'others-container--opened': othersExpanded }">
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.FastTrack"
                                          *ngIf="data.services && data.services[ServiceType.FastTrack]"
                                          (selected)="toggleOptionAttempt(ServiceType.FastTrack);
                                              closeOthersAndFocusBack()">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.FastTrack}}"
                                                  i18n="@@searchAirportFastTrack"
                                                  >AIRPORT FAST TRACK</span
                                              >
                                              <i
                                                  class="ico-fast-track ico-material"
                                                  aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Lounge"
                                          *ngIf="
                                              data.services && data.services[ServiceType.Lounge]
                                          "
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Lounge);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.Lounge}}"
                                                  i18n="@@searchAirportLounge"
                                                  >AIRPORT LOUNGE</span
                                              >
                                              <i
                                                  class="ico-lounge ico-material"
                                                  aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Parking"
                                          *ngIf="
                                              data.services && data.services[ServiceType.Parking]
                                          "
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Parking);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.Parking}}"
                                                  i18n="@@searchAirportParking"
                                                  >AIRPORT PARKING</span
                                              >
                                              <i class="ico-car" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.InternationalRail"
                                          *ngIf="data.services && data.services[ServiceType.InternationalRail]"
                                          (selected)="
                                          toggleOptionAttempt(ServiceType.InternationalRail); 
                                          closeOthersAndFocusBack()">
                                          <div class="item-label" aria-hidden="true">
                                              <span id="booking-options-{{ServiceType.InternationalRail}}" i18n="@@searchIrl"
                                                  >European Rail</span
                                              >
                                              <i class="ico-rail" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Cab"
                                          *ngIf="data.services && data.services[ServiceType.Cab]"
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Cab);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span id="booking-options-{{ServiceType.Cab}}" i18n="@@searchTaxi"
                                                  >TAXI</span
                                              >
                                              <i class="ico-taxi" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Coach"
                                          *ngIf="
                                              data.services && data.services[ServiceType.Coach]
                                          "
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Coach);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.Coach}}"
                                                  i18n="@@searchCoach"
                                                  >COACH</span
                                              >
                                              <i class="ico-bus" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Ferry"
                                          *ngIf="data.services && data.services[ServiceType.Ferry]"
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Ferry);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.Ferry}}"
                                                  i18n="@@searchFerryEurotunnel"
                                                  >Ferry/Eurotunnel</span
                                              >
                                              <i class="ico-ferries" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.Apartment"
                                          *ngIf="data.services && data.services[ServiceType.Apartment]"
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.Apartment);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.Apartment}}"
                                                  i18n="@@searchApartment"
                                                  >Apartment</span
                                              >
                                              <i class="custom_icon-apartment" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.SeasonTicket"
                                          *ngIf="data.services && data.services[ServiceType.SeasonTicket]"
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.SeasonTicket);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.SeasonTicket}}"
                                                  i18n="@@searchSeasonTickets"
                                                  >Season Tickets</span
                                              >
                                              <i class="custom_icon-season-tickets" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                      <app-product-checkbox
                                          class="booking_options_option"
                                          [service]="ServiceType.MeetingRoom"
                                          *ngIf="data.services && data.services[ServiceType.MeetingRoom]"
                                          (selected)="
                                              toggleOptionAttempt(ServiceType.MeetingRoom);
                                              closeOthersAndFocusBack()
                                          ">
                                          <div class="item-label" aria-hidden="true">
                                              <span
                                                  id="booking-options-{{ServiceType.MeetingRoom}}"
                                                  i18n="@@searchMeetingRoom"
                                                  >Meeting Room</span
                                              >
                                              <i class="ico-calendar" aria-hidden="true"></i>
                                          </div>
                                      </app-product-checkbox>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="passport_error" *ngFor="let passportError of passportErrors">
                  {{passportError}}
              </div>
              <section class="booking_panel_forms background-40">
                  <div class="form_validation" *ngIf="show_errors" id="error-message-list">
                      <span
                          role="alert"
                          id="error-alert"
                          class="visuallyhidden"
                          style="display: none"
                          tabindex="0"
                          >There are errors on the page</span
                      >
                      <h2 class="visuallyhidden">There are errors on the page</h2>
                      <div>
                          <div class="validation_error" *ngFor="let error of data.errors; let i = index">
                              <span id="error-{{i}}">{{ error | translate }}</span>
                          </div>
                      </div>
                  </div>
                  <div class="search_container"
                  [ngClass]="{ 'errored_search_container': show_errors }">
                      <form autocomplete="off" #searchForm>
                          <app-add-travellers
                              *ngIf="data.allUserdata"
                              id="add-travellers"
                              [(showGuestTravellerForm)]="showGuestTravellerForm"
                              (loadingTravellerProfile)="
                                  setLoadingTravellerProfile($event)
                              "></app-add-travellers>

                          <div *ngIf="!showGuestTravellerForm && data.services" class="clearfix">
                              <app-flight-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Flight]
                                          .priority === searchService.highest_search_priority
                                  "
                                  (toggleHotelSearchRequested)="toggleHotelSearchRequested($event)">
                              </app-flight-search>

                              <app-rail-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Rail].priority ===
                                      searchService.highest_search_priority &&
                                      !searchService.searches[ServiceType.Flight].railFromFlightSearchPerformed
                                  "
                                  (toggleHotelSearchRequested)="toggleHotelSearchRequested($event)">
                              </app-rail-search>
                              <app-irl-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.InternationalRail]
                                          .priority === searchService.highest_search_priority &&
                                      searchService.search_objects[ServiceType.InternationalRail]
                                          .chosen
                                  ">
                              </app-irl-search>
                              <app-hotel-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Hotel].priority ===
                                      searchService.highest_search_priority
                                  ">
                              </app-hotel-search>
                              <app-car-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Car].priority ===
                                      searchService.highest_search_priority
                                  "
                                  (toggleHotelSearchRequested)="toggleHotelSearchRequested($event)">
                              </app-car-search>
                              <app-eurostar-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Eurostar]
                                          .priority === searchService.highest_search_priority &&
                                          !searchService.searches[ServiceType.Flight].eurostarFromFlightSearchPerformed
                                  "
                                  (toggleHotelSearchRequested)="toggleHotelSearchRequested($event)">
                              </app-eurostar-search>

                              <app-lounge-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Lounge]
                                          .priority === searchService.highest_search_priority
                                  ">
                              </app-lounge-search>
                              <app-parking-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Parking]
                                          .priority === searchService.highest_search_priority
                                  ">
                              </app-parking-search>
                              <app-fast-track-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.FastTrack]
                                          .priority === searchService.highest_search_priority
                                  ">
                              </app-fast-track-search>
                              <!-- <app-eurostar-search *ngIf="
                              searchService.search_objects.eurostar.priority ===
                              searchService.highest_search_priority
                              ">
                              </app-eurostar-search> -->
                              <app-taxi-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Cab].priority ===
                                      searchService.highest_search_priority
                                  ">
                              </app-taxi-search>
                              <app-ferry-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Ferry].priority ===
                                      searchService.highest_search_priority
                                  ">
                              </app-ferry-search>
                              <app-apartment-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.Apartment].priority ===
                                      searchService.highest_search_priority
                                  ">
                              </app-apartment-search>
                              <app-season-tickets-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.SeasonTicket].priority ===
                                      searchService.highest_search_priority
                                  ">
                              </app-season-tickets-search>
                              <app-meeting-room-search
                                  *ngIf="
                                      searchService.search_objects[ServiceType.MeetingRoom]
                                          .priority === searchService.highest_search_priority
                                  ">
                              </app-meeting-room-search>
                              <!-- <app-coach-search *ngIf="
                searchService.search_objects.coach.priority ===
                searchService.highest_search_priority
              ">
              </app-coach-search> -->
                              <div class="flex-row-container flex-row-container--right">
                                  <button
                                      type="button"
                                      id="search-all"
                                      (click)="fetchSearchAll()"
                                      [disabled]="searchService.highest_search_priority < 1 || data.loadingDate"
                                      *ngIf="
                                          selectedOptions().length &&
                                          !( searchService.searches[
                                          ServiceType.InternationalRail
                                           ] &&
                                              searchService.searches[
                                                  ServiceType.InternationalRail
                                              ].offlineRequest &&
                                              selectedOptions().length &&
                                              searchService.search_objects[
                                                  ServiceType.InternationalRail
                                              ].priority === searchService.highest_search_priority
                                          ) &&
                                          searchService.search_objects[ServiceType.Ferry].priority !== searchService.highest_search_priority &&
                                          searchService.search_objects[ServiceType.SeasonTicket].priority !== searchService.highest_search_priority && 
                                          searchService.search_objects[ServiceType.MeetingRoom].priority !== searchService.highest_search_priority
                                      "
                                      class="large-button large-button--search"
                                      type="button">
                                      <span *ngIf="searchService.search_objects[ServiceType.Apartment].priority !==
                                      searchService.highest_search_priority" i18n="@@search_Search">Search</span>
                                      <span *ngIf="searchService.search_objects[ServiceType.Apartment].priority ===
                                      searchService.highest_search_priority" i18n="@@search_submitform">Submit Form</span>
                                  </button>
                                  <button
                                      type="button"
                                      id="submit-all"
                                      (click)="fetchSearchAll()"
                                      [disabled]="
                                          searchService.highest_search_priority < 1 ||
                                          loadingOfflineRequestDialog
                                      "
                                      *ngIf="
                                          selectedOptions().length &&
                                          searchService.searches[ServiceType.InternationalRail] &&
                                          searchService.searches[ServiceType.InternationalRail]
                                              .offlineRequest &&
                                          selectedOptions().length &&
                                          searchService.search_objects[
                                              ServiceType.InternationalRail
                                          ].priority === searchService.highest_search_priority ||
                                          searchService.search_objects[ServiceType.Ferry].priority === searchService.highest_search_priority || 
                                          searchService.search_objects[ServiceType.SeasonTicket].priority === searchService.highest_search_priority || 
                                          searchService.search_objects[ServiceType.MeetingRoom].priority === searchService.highest_search_priority
                                      "
                                      class="large-button large-button--search"
                                      type="button"
                                      i18n="@@search_submit">
                                      Submit
                                  </button>
                              </div>
                          </div>
                      </form>
                      <h2
                          class="light-center"
                          *ngIf="!selectedOptions().length"
                          i18n="@@searchPleaseSelect">
                          Please, select at least one option above.
                      </h2>
                  </div>
              </section>
          </section>

          <div class="app-right secondary-text-colour" 
              [@slideInOut]="basketService.menuState" 
              id="side-menu"
              #basket>
              <button
                tabindex="0" 
                aria-label="Menu"
                id="basket_expander_button"
                type="button"
                #basketExpanderButton
                (click)="openRightPanelMenu(selectedRightPanelItem); toggleMenu()"
                class="basket-button">
                <i class="ico-lines"></i>
              </button>
      
              <div class="right-panel">
                <span i18n="@@CurrentlyViewing2" aria-label="Currently Viewing">Currently Viewing</span> 
                <div class="right-panel-active-item"  i18n-aria-label="@@AriaAddAnotherTrain"  attr.aria-label="{{ selectedRightPanelItem }}">{{selectedRightPanelItem}}</div>
                <div class="right-panel-menu-container">
                  <div class="right-panel-menu-button"
                    role="button"
                    tabindex="0"
                    (click)="menuOpen = !menuOpen"
                    (keydown.space)="menuOpen = !menuOpen"
                    (keydown.enter)="menuOpen = !menuOpen"
                    attr.aria-expanded="{{menuOpen}}"
                    attr.aria-label="Currently Viewing {{selectedRightPanelItem}} - Expand side panel options">
                    <i [ngClass]="menuOpen ? 'ico-tri-up' : 'ico-tri-down'"></i>
                  </div>
                  <div class="right-panel-menu"
                      [ngClass]='{"right-panel-menu--expanded": menuOpen}'
                      id="right-panel-menu">
                    <div class="right-panel-menu-item" *ngIf="RightPanelMenu.RecentSearches !== selectedRightPanelItem"
                        [tabindex]="menuOpen ? 0 : -1"
                        attr.aria-label="{{RightPanelMenu.RecentSearches}}"
                        (click)='openRightPanelMenu(RightPanelMenu.RecentSearches)'
                        attr.aria-hidden="{{!menuOpen}}"
                        role="button"
                        i18n="@@RecentSearches"
                        id="recent-searches"
                        (keydown.enter)="openRightPanelMenu(RightPanelMenu.RecentSearches)">
                        {{RightPanelMenu.RecentSearches}}
                    </div>
                    <div class="right-panel-menu-item" *ngIf="RightPanelMenu.MyBaskets !== selectedRightPanelItem"
                        [tabindex]="menuOpen ? 0 : -1"
                        attr.aria-label="{{RightPanelMenu.MyBaskets}}"
                        role="button"
                        (click)="openRightPanelMenu(RightPanelMenu.MyBaskets)"
                        i18n="@@MyBaskets"
                        id="open-baskets"
                        attr.aria-hidden="{{!menuOpen}}"
                        (keydown.enter)="openRightPanelMenu(RightPanelMenu.MyBaskets)">
                        {{RightPanelMenu.MyBaskets}}
                    </div>
                      <div class="right-panel-menu-item" *ngIf="RightPanelMenu.FrequentTravellers !== selectedRightPanelItem"
                          id="frequent-travellers"
                          [tabindex]="menuOpen ? 0 : -1"
                          attr.aria-label="{{RightPanelMenu.FrequentTravellers}}"
                          role="button"
                          (click)="openRightPanelMenu(RightPanelMenu.FrequentTravellers)"
                          i18n="@@MyBaskets"
                          attr.aria-hidden="{{!menuOpen}}"
                          (keydown.enter)="openRightPanelMenu(RightPanelMenu.FrequentTravellers)">
                          {{RightPanelMenu.FrequentTravellers}}
                      </div>
                  </div>
                </div>
              </div>
      
              <div id="recent_searches" *ngIf="showRecentSearch">
                <app-recent-searches
                  [hidden]="loadingTravellerProfile"
                  class="app-recent-searches"></app-recent-searches>
              </div>
      
              <div id="basket" *ngIf="showMyBaskets">
                <app-basket-panel
                  class="app-basket-panel"
                  (click)="onPanelClick($event)"></app-basket-panel>
              </div>

              <div tabindex="0" id="FrequentTravellers" class="app-frequent-travellers" *ngIf="showFrequentTravellers">
                  <app-frequent-travellers
                      class="main-container"
                  ></app-frequent-travellers>
              </div>
            </div>
          </div>
        </ng-container>
    </div>

<app-loading-screen *ngIf="becomingUser()">
  <span title i18n="@@mainBecomingUser">Becoming User ...</span>
</app-loading-screen>
<app-loading-screen *ngIf="loadingSSO">
  <span title i18n="@@mainLoadingSSO">Performing flight search ...</span>
</app-loading-screen>
  
