<div class="modal-header">
  <h3 class="modal-title" i18n="@@info">Info</h3>
  <button type="button" class="light-icon-button close" aria-label="Close" (click)="activeModal.close(false)" ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="hotelDetail">
    <div class="hotel-header">
      <img [src]="thumbUrl" class="hotel-header-image"  alt="image of {{hotelDetail.name ? hotelDetail.name : 'hotel'}}" />
      <div class="hotel-header-details">
        <h2 [innerHTML]='hotelDetail.name'></h2>
        <div class="address" *ngIf="address">{{address}}</div>
        <div class="phone-number" *ngIf="hotelDetail?.address?.telephone">
          <i class="ico-call-phone" aria-label="Phone number"></i>
          {{hotelDetail?.address?.telephone}}
        </div>
        <div class="fax" *ngIf="hotelDetail?.address?.facsimile">
          <i class="ico-icon-print" aria-label="Fax"></i>
          {{hotelDetail?.address?.facsimile}}
        </div>
      </div>
    </div>

    <!-- LOCATION -->
    <div *ngIf="hotelDetail.transports">
      <div class="hotel-location">
        <b i18n="@@itemHotelsLocation">Location</b>:
        <span *ngFor="let transport of hotelDetail.transports">
          <ng-container *ngIf="transport.type ==='MetroStation'">
            <span class="location-distance">
              <i class="ico-metro" aria-hidden="true"></i>
              <span class="visuallyhidden">Closest Underground Station</span>
              {{transport.distance | number:'1.1-1'}}
              <span i18n="@@itemHotelsMiles">miles</span>
            </span>
          </ng-container>

          <ng-container *ngIf="transport.type ==='TrainStation'">
            <span class="location-distance">
              <i class="ico-rail" aria-hidden="true"></i>
              <span class="visuallyhidden">Closest Train Station</span>
              {{transport.distance | number:'1.1-1'}}
              <span i18n="@@itemHotelsMiles">miles</span>
            </span>
          </ng-container>

          <ng-container *ngIf="transport.type ==='Airport'">
            <span class="location-distance">
              <i class="ico-flight" aria-hidden="true"></i>
              <span class="visuallyhidden">Closest Airport</span>
              {{transport.distance | number:'1.1-1'}}
              <span i18n="@@itemHotelsMiles">miles</span>
            </span>
            <span class="location-distance" *ngIf="hotelDetail?.Details?.airport_details && hotelDetail?.Details?.airport_details?.name">
              <i class="ico-flight" aria-hidden="true"></i>{{transport.name}}
            </span>
          </ng-container>
        </span>
      </div>
    </div>
    <!-- END LOCATION -->

    <!-- FACILITIES -->
    <div class="facilities">
      <div class="hotel-facilities" *ngIf="hotelFacilities.length">
        <h4 i18n="@@hotelFacilities">Hotel Facilities</h4>
        <div *ngFor="let facility of hotelFacilities" class="facility-wrapper">
          <img class="facility-img" [src]="facility.icon" alt="{{facility.name}}">{{facility.name | translate}}
        </div>
      </div>
      <div class="room-facilities" *ngIf="roomFacilities.length">
        <h4 i18n="@@roomFacilities">Room Facilities</h4>
        <div *ngFor="let facility of roomFacilities" class="facility-wrapper">
          <img class="facility-img" [src]="facility.icon" alt="{{facility.name}}">{{facility.name | translate}}
        </div>
      </div>
    </div>
    <!-- END FACILITIES -->

    <div *ngIf="hotelDetail?.source === 'Conferma'">
      <p class="infoDisclaimer" i18n="@@infoDisclaimer">* This information was collected from third party systems. Conferma Ltd accepts no responsibility for the accuracy of the data provided.</p>
      <img src="https://images.sabscorp.com/images/logo/conferma.gif">
    </div>
  </div>

  <div *ngIf="!hotelDetail" i18n="@@noData">
    No data...
  </div>
</div>
