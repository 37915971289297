<div class="sorting-toggle">
       <label for="sortingToggle"
       i18n="@@SortingTitle"
       id="sortingLabel"
              class="sorting-toggle__label">Sort by:</label>
       <mat-select
                   name="sorting-toggle"
                   aria-describedby="sortinghint"
                   id="sortingToggle"
                   aria-label="Sort by:"
                   (selectionChange)="select($event.value)"
                   panelClass="sort-select"
                   class="input-box input-box--no-icon input-box--no-caret"
                   [placeholder]="placeholderText | translate"
                   [(value)]="selected"
                   [hideSingleSelectionIndicator]="true"
                   style="line-height:41px; font-size:17px;">
              <mat-option *ngFor="let type of sortTypes"
                          [value]="type.title"
                          class="sort-options">
                     <span>{{type.title | translate}}</span>
                     <i class='{{type.icon}}'
                        *ngIf="type.icon && type.icon !== 'ico-gbp'"></i>
                     <img src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/filter-coin-icon.svg" *ngIf="type.icon === 'ico-gbp'" class="coin-svg" />
              </mat-option>
       </mat-select>
       <span hidden
             id="sortinghint">Used to sort results</span>
</div>
