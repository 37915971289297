import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import {
  OBTRailJourneyFare,
  RailEnterpriseSearchInterface,
  OBTRailJourneyOption,
  OBTRailFareTypes,
  OBTRailFare,
  RailFareTypes,
  RailDirection,
  ServiceProvider,
  RailBasketItem
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  WithSubscriptionComponent,
  ModalOpenerService,
  EnterpriseBasketService,
  RailSearchJourneyType,
  RailJourneyFare,
  HelperRoutines,
  DiscountType,
  UserService,
  RailSearchComposition
} from '@sabstravtech/obtservices/angular';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { Helpers } from '../../../../vendor/classes/helpers';
import { IRailTicketType } from '../../../../vendor/interfaces/rail-ticket-type-interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
  selector:
    'app-rail-results-block[sourceId][journeys][from][to][fareTypes][expandedTickets][direction]',
  templateUrl: './rail-results-block.component.html',
  styleUrls: ['./rail-results-block.component.scss']
})
export class RailResultsBlockComponent extends WithSubscriptionComponent implements OnInit {
  @Input() sourceId: number;
  @Input() journeys: OBTRailJourneyOption[];
  @Input() outboundJourneys: OBTRailJourneyOption[];
  @Input() from: string;
  @Input() to: string;
  @Input() date: string;
  @Input() fareTypes: Record<OBTRailFareTypes, string[]>;
  @Input() expandedTickets: Record<OBTRailFareTypes, Record<string, boolean>>;
  @Input() direction: RailDirection;
  @Input() isExchange: Boolean = false;

  @Output() earlier = new EventEmitter<void>();
  @Output() later = new EventEmitter<void>();
  @Output() prevDay = new EventEmitter<void>();
  @Output() nextDay = new EventEmitter<void>();
  @Output() exchangedItemDetail: EventEmitter<RailBasketItem> = new EventEmitter();
  readonly eTicketIcon = 'https://images.sabscorp.com/images/sabs2/icons/eticket.jpg';

  resultItemType = resultIcons;

  ticketTypes: RailFareTypes[];
  RailFareTypes: typeof RailFareTypes = RailFareTypes;
  RailDirection: typeof RailDirection = RailDirection;
  ServiceProvider: typeof ServiceProvider = ServiceProvider;
  searchParams: RailEnterpriseSearchInterface;
  canOverride: boolean = false;
  sourceName: string = '';

  constructor(
    private searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private basketService: EnterpriseBasketService,
    private helpers: HelperRoutines,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Rail];
    this.getTicketTypes = this.getTicketTypes.bind(this);
    this.ticketTypes = this.searchParams.ticketTypes;
    console.log(this.searchParams);
  }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    this.sourceName = this.userService.findService(this.sourceId.toString())?.name;
  }

  // @Memorise()
  fieldFromObject(
    journey: OBTRailJourneyOption,
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): any {
    if (
      journeyType === RailFareTypes.openReturnJourneyFares &&
      this.direction === RailDirection.Inbound
    ) {
      return null;
    }

    if (isExpanded) {
      return Helpers.fieldFromObjectArray<OBTRailJourneyFare>(journey, [
        journeyType,
        ticketName,
        index
      ]);
    }
    return Helpers.fieldFromObjectArray<OBTRailJourneyFare[]>(journey, [
      journeyType,
      ticketName
    ])?.reduce(
      (
        lowest: OBTRailJourneyFare | null,
        ticket: OBTRailJourneyFare
      ): OBTRailJourneyFare | null => {
        if (!ticket) {
          return lowest;
        }
        if (lowest && lowest.price < ticket.price) {
          return lowest;
        } else {
          return ticket || null;
        }
      },
      null
    );
  }

  async onSelectTicket(
    railJourneyOption: OBTRailJourneyOption,
    railFare: OBTRailJourneyFare,
    section: OBTRailFareTypes
  ) {
    if (this.isExchange) {
      const basketItem = await this.searchParams.selectTicketForExchange(
        this.direction,
        railJourneyOption,
        railFare,
        this.sourceId.toString(),
        section
      );

      this.exchangedItemDetail.emit(basketItem);
    } else {
      if (railFare.selectable) {
        this.searchParams.selectTicket(
          this.direction,
          railJourneyOption,
          railFare,
          this.sourceId.toString(),
          section
        );
        if (
          this.searchParams.chosenSearchType === RailSearchJourneyType.SingleJourney ||
          (this.searchParams.chosenSearchType === RailSearchJourneyType.ReturnJourney &&
            this.direction === RailDirection.Inbound)
        ) {
          if (this.searchService.searches[ServiceType.Flight].railFromFlightSearchPerformed) {
            this.searchService.search_objects[ServiceType.Flight].chosen = false;
          }

          if (this.searchParams.selectedTicket) {
            this.basketService.toggleMenu();
          }
        } else {
          //For keyboard users, move focus to the return table
          const blocks = this.document.getElementsByTagName('app-rail-results-block');
          const inBoundBlock = blocks[1];
          const firstInboundTicket = inBoundBlock.getElementsByClassName('ticket_details_link')[0];
          const returnJourneyFares =  inBoundBlock.getElementsByClassName('selectable_return')[0];
          if (returnJourneyFares && section === RailFareTypes.returnJourneyFares) {
            (returnJourneyFares as HTMLElement).focus();
          } else {
            (firstInboundTicket as HTMLElement).focus();
          }
        }
      }
    }
  }

  async openRailTicketDetailsDialog(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ) {
    if (this.sourceName === this.ServiceProvider.Trainline) {
      if (
        this.journeys?.some(
          j =>
            !!this.fieldFromObject(
              j,
              isExpanded,
              fareTypes,
              journeyType,
              ticketName,
              index
            )?.identifiers?.composition?.includes(RailSearchComposition.InterchangeSplit)
        )
      ) {
        return;
      }
    }

    const journey = this.journeys.find((journey: OBTRailJourneyOption) => {
      return !!this.fieldFromObject(journey, isExpanded, fareTypes, journeyType, ticketName, index);
    });
    if (journey) {
      const { terms, identifiers } = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );

      if (terms?.length > 1 && identifiers?.isSplit) {
        await this.modalService.open(
          LightningModalTypes.RailFareListComponent,
          { centered: true },
          {
            outboundFareTermsList: terms,
            outboundFares: identifiers.fares,
            splitType: identifiers.composition?.[0],
            origin: journey.from,
            destination: journey.to,
            serviceName: this.sourceName
          }
        );
      } else {
        await this.modalService.open(
          LightningModalTypes.RailFareTermsComponent,
          { centered: true },
          {
            ticketDetails: terms?.[0]?.terms
          }
        );
      }
    }
  }

  getTicketTypes(type: OBTRailFareTypes): IRailTicketType[] {
    if (!this.journeys?.length) {
      return [];
    }

    if (type === RailFareTypes.returnJourneyFares && this.direction === RailDirection.Inbound) {
      const outboundFares = this.sortTickets(this.outboundJourneys, type);
      const inboundFares = this.sortTickets(this.journeys, type);

      // Sort inbound fares to be the same as outbound
      return outboundFares
        .map(x => x.key)
        .reduce((curr, next) => {
          const inboundFare = inboundFares.find(x => x.key === next);
          curr.push(inboundFare);
          return curr;
        }, []);
    }

    if (type !== RailFareTypes.returnJourneyFares) {
      this.journeys.forEach(j => {
        for (const p in j[type]) {
          if (j[type][p].filter(o => o === null).length === j[type][p].length) {
            delete j[type][p];
          }
        }
      });
    }

    return this.sortTickets(this.journeys, type);
  }

  sortTickets(journeys: OBTRailJourneyOption[], type: OBTRailFareTypes) {
    let ticketsObject = {} as IRailTicketType;
    return journeys.reduce(
      (combineJourneys: IRailTicketType[], journey: OBTRailJourneyOption): IRailTicketType[] => {
        const journeyType = this.sortJourneys(ticketsObject, journey[type]);
        combineJourneys = Object.values(journeyType).sort((a, b) => a.price - b.price);
        return combineJourneys;
      },
      []
    );
  }

  sortJourneys(container: IRailTicketType, journey: OBTRailFare): IRailTicketType {
    return Object.entries(journey).reduce(
      (current: IRailTicketType, [key, fares]: [string, OBTRailJourneyFare[]]): IRailTicketType => {
        if (fares?.length) {
          const lowestPrice = this.getLowestFarePrice(fares);
          if (
            container[key] &&
            lowestPrice &&
            (!container[key]?.price || container[key]?.price > lowestPrice)
          ) {
            container[key].price = lowestPrice;
          } else if (!container[key]) {
            container[key] = {
              key: key,
              value: fares.length,
              price: lowestPrice
            };
          }
          current = container;
        }
        return current;
      },
      {} as IRailTicketType
    );
  }

  getLowestFarePrice(fares: OBTRailJourneyFare[]): number {
    return fares.reduce((cheapestprice: number, fare: OBTRailJourneyFare): number => {
      if (fare?.price && (!cheapestprice || cheapestprice > fare?.price)) {
        cheapestprice = fare?.price;
      }
      return cheapestprice;
    }, null);
  }

  isEticketAvailable(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): any {
    let eticket = false;
    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket) {
        eticket = !!ticket.eTicketAvailable;
      }
    });
    return eticket;
  }

  isLimitedRow(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    let limited = false;
    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket) {
        limited = !!(ticket.availableSeats && ticket.availableSeats < 9);
        if (limited) {
          return limited;
        }
      }
    });
    return limited;
  }

  isGroupFare(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    let found = false;

    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket?.railcardCode) {
        if (this.helpers.isGroupTicket(ticket.railcardCode)) {
          found = true;
        }
      } else if (ticket?.discountInfo?.some(x => x.type === DiscountType.Groupsave)) {
        found = true;
      }
    });

    return found;
  }

  isTrainlineSplitFare(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    return this.journeys.some((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      return ticket?.identifiers?.isSplit;
    });
  }

  showSplitTooltip(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    return this.journeys.some((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      return (
        ticket?.identifiers?.isSplit &&
        ticket?.identifiers?.composition.includes(RailSearchComposition.InterchangeSplit)
      );
    });
  }
}

